import React from 'react';
import Navigation from '../components/Navigation';

const About = () => {
    return (
        <div>
            <Navigation/>
            <div className="about">
                <h1>About</h1>
                <br/>
                <article>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus porro saepe atque a tempora non voluptatum voluptates. Dolores delectus eveniet id cupiditate omnis unde modi perspiciatis tempora similique asperiores? Vitae deleniti adipisci voluptatibus maiores dolor suscipit porro enim quo! Omnis!
                </article>
                <br/>
                <br/>
                <article>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam, similique, eaque eum atque et asperiores quas accusantium, aperiam harum ratione unde distinctio numquam ad nostrum. Veritatis dolor explicabo quo possimus ipsam! Illum sunt neque esse ullam voluptates animi, iure iste quas laborum dignissimos dolore ducimus accusantium fugit quia rem consequatur recusandae dolor ad eum sapiente nostrum perspiciatis. Similique quae, nesciunt accusamus fuga quas quos est dicta culpa reiciendis, fugit ducimus. Deserunt distinctio, hic numquam neque quaerat adipisci, earum fugiat labore ratione nostrum nulla assumenda recusandae placeat cumque modi mollitia aut eveniet itaque nobis dignissimos laudantium ipsa rem rerum. Quia iste error suscipit sunt. Provident magnam mollitia placeat ut beatae nemo quas magni saepe maiores vero eum doloremque obcaecati quibusdam, ducimus dolor natus animi consequuntur distinctio minima facilis ad expedita alias, maxime incidunt. Animi deleniti placeat ipsum est expedita aperiam dignissimos id dolor distinctio mollitia porro, incidunt neque officiis qui ad?
                </article>
                </div>
        </div>
    );
};

export default About;